<template>
	<div>
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color:#666">评价标签</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<div class=" bottom left">
				<el-button type="primary" icon="el-icon-plus" @click="addLebel">新增评价标签</el-button>
			</div>
			<el-table :data="commentLableTable" style="width: 100%" :default-sort="{prop:'label_sort',order:'descending'}" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column type="index" label="ID" width="100px"></el-table-column>
				<el-table-column prop="label_name" label="标签名称"></el-table-column>
				<el-table-column prop="label_sort" label="排序值"></el-table-column>
				<el-table-column prop="add_time" label="创建时间"></el-table-column>
				<el-table-column label="是否上架">
					<template slot-scope="scope">
						<div>
							<el-switch v-model="scope.row.is_on_sale" @change="changeState(scope.row)"></el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" plain @click="editLabel(scope.row)">编辑</el-button>
							<el-button type="danger" size="mini" plain @click="removeLabel(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增评价标签 -->
			<el-dialog :title="title" :visible.sync="addVisible" width="30%">
				<el-form ref="addForm" :model="addForm" label-width="80px" :rules="addFormRules">
					<el-row :gutter="10">
						<el-col :span="22">
							<el-form-item label="标签名称" prop="label_name">
								<el-input v-model="addForm.label_name" maxlength="20" show-word-limit placeholder="请输入标签名称"></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row :gutter="10">
						<el-col :span="22">
							<el-form-item label="排序值" prop="label_sort">
								<el-input v-model.number="addForm.label_sort" placeholder="请输入排序值"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="2">
							<el-tooltip class="item" effect="dark" content="值越大,排序越靠前" placement="right">
								<i class="el-icon-question" style="font-size: 18px"></i>
							</el-tooltip>
						</el-col>
					</el-row>
				</el-form>
				<div class="center">
					<el-button type="default" @click="closeAdd">取消</el-button>
					<el-button type="primary" @click="subAdd">确定</el-button>
				</div>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "新增评价标签",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				total: 0,
				commentLableTable: [],
				editFormRules: {
					label_name: [{
						required: true,
						message: '请输入标签名称',
						trigger: 'blur'
					}],
					sort: [{
							required: true,
							message: '请输入排序值',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '请输入数字'
						},
					]
				},
				addFormRules: {
					label_name: [{
						required: true,
						message: '请输入标签名称',
						trigger: 'blur'
					}],
					label_sort: [{
							required: true,
							message: '请输入排序值',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '请输入数字'
						},
					]
				},
				addVisible: false,
				addForm: {
					label_name: '',
					label_sort: 10
				},

			}
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getCommentLabel();
			}
		},
		methods: {
			getCommentLabel(){
				var url = 'comment_label/label_list';
				let params = {
					label_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.commentLableTable = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//修改上架
			changeState(item) {
				var url = 'comment_label/updateOnSale';
				let params = {
					label_id: item.label_id,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						this.$message.success("操作成功");
					} else {
						this.$message.error(res.msg);
					}
				}).catch((err) => {
					this.$message.error('网络错误');
				});
			},
			//修改弹窗
			editLabel(item) {
				this.title = '编辑评价标签';
				this.addForm = item;
				this.addVisible = true;
			},
			//删除
			removeLabel(item) {
				this.$confirm('你确定要删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'comment_label/deleteLabel';
					let params = {
						label_id: item.label_id,
					};
					that.fd_post(url, params).then((res) => {
						if(res.status) {
							that.$message.success('删除成功');
							that.getCommentLabel();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			//添加弹窗
			addLebel() {
				this.title = "新增评价标签";
				this.addVisible = true;
				this.addForm = {
					label_name: '',
					label_sort: 10
				};
			},
			//取消添加
			closeAdd() {
				this.addVisible = false
			},
			//确认添加
			subAdd() {
				this.$refs.addForm.validate(value => {
					if(!value) return;
					if(timer != null){clearTimeout(timer);}
    				timer = setTimeout(() => {
						var url = 'comment_label/addOrUpdate';
						let params = that.addForm;
						that.fd_post(url, params).then((res) => {
							if(res.status) {
								that.addVisible = false;
								that.$refs.addForm.resetFields();
								that.$message.success('操作成功');
								that.getCommentLabel();
							} else {
								that.$message.error(res.msg);
							}
						}).catch((err) => {
							that.$message.error('网络错误');
						});
					},1000);
				})
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getCommentLabel();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getCommentLabel();
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import './Order_commentLabel.scss';
</style>